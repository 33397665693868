.button {
  --height: 52px;
  --icon-size: 32px;
  --min-height: var(--height);

  --horizontal-padding: 24px;
  --vertical-padding: 0;
  --gap: 8px;

  --color: var(--black);
  --background-color: var(--primary-500);

  position: relative;

  display: inline-block;
  height: var(--height);
  min-height: var(--min-height);
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);
  overflow: hidden;

  text-decoration: none;

  outline: none;
  cursor: pointer;
  user-select: none;
  border-radius: 9999px;
  transition-duration: 450ms;
  transition-property: color;
}
.button--disabled {
  --background-color: lightgrey !important;

  cursor: default;
}

.button--loading {
  --background-color: var(--primary-500) !important;

  cursor: default;
}
/* .button--has-right-icon {
  padding-right: calc((var(--height) - var(--icon-size)) / 2);
} */
@media (--mobile) {
  .button {
    --horizontal-padding: 16px;
    --gap: 4px;
  }
}

/** Themes **/

.button--theme-white {
  --color: var(--black);
  --background-color: var(--white);
}

/** Height **/

.button--height-small {
  --height: 42px;
}

/** Hover state **/

.button:not(.button--disabled):hover {
  --color: var(--black);
  --background-color: var(--white);
}
.button--theme-white:not(.button--disabled):hover {
  --color: var(--black);
  --background-color: var(--primary-200);
}

/** Active state **/

.button:not(.button--disabled):active {
  --color: var(--black);
  --background-color: var(--primary-200);
}
.button--theme-white:not(.button--disabled):active {
  --color: var(--black);
  --background-color: var(--white);
}

/** Background **/

.button__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--background-color);
  transition-duration: 200ms;
  transition-property: opacity, background-color;
}

.button--theme-black:not(.button--disabled) {
  --background-color: var(--black);
}

/** Content **/

.button__content {
  display: inline-flex;
  align-items: center;
  height: var(--height);
  min-height: var(--min-height);
}
.button--width-full .button__content {
  width: 100%;
}

.button__text {
  z-index: 1;
  position: relative;

  flex: 1;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  color: var(--color);
  font-family: var(--font-family-text);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.03em;
  text-align: center;
}
@media (--mobile) {
  .button__text {
    font-size: 15px;
  }
}

.button--impacting .button__text {
  font-weight: 700;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.button__text strong {
  font-weight: 800;
}

.button__icon {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--icon-size);
  height: var(--icon-size);

  transition-duration: 200ms;
  transition-property: all;
}
* + .button__icon {
  margin-left: var(--gap);
}

.button--theme-white.button--disabled .button__icon {
  filter: grayscale(1);

  transition-duration: 200ms;
  transition-property: all;
}

.button__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
