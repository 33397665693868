.text {
  --vertical-padding: 100px;
  --font-size: 20px;

  position: relative;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
.text--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}
@media (--tablet) {
  .text {
    --vertical-padding: 60px;
  }
}
@media (--mobile) {
  .text {
    --vertical-padding: 40px;
    --font-size: 16px;
  }
}

.text__wrap {
  max-width: 73ch;
}

.text__wrap h2 {
  font-family: var(--font-family-title);
  font-size: 30px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.03em;
}
@media (--mobile) {
  .text__wrap h2 {
    font-size: 24px;
  }
}

.text__wrap b {
  color: var(--primary-500);
}

.text__wrap * + h2 {
  margin-top: 64px;
}
@media (--mobile) {
  .text__wrap * + h2 {
    margin-top: 32px;
  }
}

.text__wrap p {
  font-size: var(--font-size);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
}

.text__wrap * + p {
  margin-top: 16px;
}
.text__wrap h2 + p {
  margin-top: 32px;
}
@media (--mobile) {
  .text__wrap * + p {
    margin-top: 12px;
  }
  .text__wrap h2 + p {
    margin-top: 24px;
  }
}
.text__wrap a {
  color: var(--white);
  font-size: var(--font-size);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 0.08em;
}
