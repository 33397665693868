.level-up {
  --vertical-padding: 100px;

  position: relative;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
@media (--tablet) {
  .level-up {
    --vertical-padding: 60px;
  }
}
@media (--mobile) {
  .level-up {
    --vertical-padding: 40px;
  }
}

* + .level-up {
  border-top: 5px solid var(--primary-500);
}

.comparative-table + .level-up {
  padding-top: 0;
}

.level-up--without-border {
  border-top: none;
}

.level-up__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      130deg,
      rgba(var(--black-rgb), 0.8) 16%,
      rgba(var(--black-rgb), 0) 60%
    ),
    linear-gradient(
      123deg,
      rgba(var(--black-rgb), 0.8) 14%,
      rgba(var(--black-rgb), 0) 58%
    );
}
.level-up--without-gradient > .level-up__gradient {
  display: none;
}

.level-up .max-width {
  position: relative;
}

.level-up__title {
  max-width: 35ch;
  margin-left: auto;
  margin-right: auto;

  color: var(--white);
  text-align: center;
  font-family: var(--font-family-title);
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.03em;
  text-align: center;
}
.level-up__title strong {
  color: var(--primary-500);
  font-weight: 700;
}
@media (--tablet) {
  .level-up__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .level-up__title {
    font-size: 26px;
  }
}

.level-up__blocks {
  --gap: 24px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);

  list-style: none;
}
* + .level-up__blocks {
  margin-top: var(--spacing-56);
}
@media (--tablet) {
  .level-up__blocks {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (--mobile) {
  .level-up__blocks {
    --gap: 20px;
  }
}

.level-up__blocks__item {
  --border-radius: 16px;

  position: relative;

  padding: var(--gap);

  border-radius: var(--border-radius);
  border: 1px solid rgba(var(--white-rgb), 0);
  background-image: linear-gradient(
      0deg,
      rgba(var(--black-rgb), 0.2) 0%,
      rgba(var(--black-rgb), 0.2) 100%
    ),
    linear-gradient(
      130deg,
      rgba(var(--white-rgb), 0.2) 0%,
      rgba(var(--white-rgb), 0.1) 100%
    );
  backdrop-filter: blur(12px);
}
.level-up__blocks__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  pointer-events: none;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    )
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.level-up__blocks__item--title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.level-up__blocks__item__icon {
  --size: 44px;

  display: block;
  width: var(--size);
  height: var(--size);
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;

  user-select: none;
}
@media (--mobile) {
  .level-up__blocks__item__icon {
    --size: 36px;
  }
}

.level-up__blocks__item__title {
  max-width: 20ch;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: var(--font-family-title);
  font-size: 21px;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.03em;
}

.level-up__blocks__item__title strong {
  font-weight: 700;
  color: var(--primary-500);
}

.level-up__blocks__item__content {
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.03em;
}

.level-up__blocks__item__content b {
  color: var(--primary-500);
}

* + .level-up__blocks__item__content,
* + .level-up__blocks__item__title {
  margin-top: var(--gap);
}

.level-up__blocks__item__content * + p {
  margin-top: 12px;
}

* + .level-up__cta {
  margin-top: var(--spacing-56);

  text-align: center;
}
