* + .founder {
  margin-top: 64px;
}
@media (--mobile) {
  * + .founder {
    margin-top: 60px;
  }
}

.founder {
  --vertical-padding: 100px;
  --mobile-bottom-offset: 80px;
  --mobile-bottom-padding: 23px;
}

.founder--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}

.founder .max-width {
  position: relative;

  display: grid;
  grid-template-columns: 0.9fr 1fr;
  column-gap: 85px;
  row-gap: 20px;
}

@media (--tablet) {
  .founder .max-width {
    grid-template-columns: 1.2fr 0.8fr;
    column-gap: 40px;
  }
}

@media (--mobile) {
  .founder .max-width {
    display: block;
  }

  .founder {
    padding-bottom: var(--mobile-bottom-offset);
  }
}

.founder__top__title {
  color: var(--white);
  font-family: var(--font-family-title);
  font-size: 50px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 1.5px;
}

.founder__top__title strong {
  color: #f0fa1d;
}

* + .founder__top__content {
  margin-top: 32px;
}

.founder__top {
  grid-column: 1;
  grid-row: 1;
}

.founder__top__content,
.founder__content-blok {
  color: var(--white);
  font-family: var(--font-family-text);
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.6px;
}

.founder__content-blok p:last-of-type:after {
  content: '';
  display: inline-block;
  width: var(--icon-width);
}

.founder__top__content b,
.founder__content-blok b {
  color: #f0fa1d;
  font-weight: 400;
}

.founder__image {
  width: 100%;
  grid-column: 2;
  grid-row: 1 / 3;
}

.founder__image img {
  height: auto;
  width: 100%;
  object-fit: contain;
}

.founder__content-blok {
  --icon-width: 34px;
  --icon-height: 23px;

  position: relative;

  padding-top: var(--icon-height);

  grid-column: 1;
  grid-row: 2;
}

.founder__content-blok p:first-of-type {
  text-indent: var(--icon-width);
}

.founder__content-blok__quotes-first,
.founder__content-blok__quotes-last {
  position: absolute;
  height: var(--icon-height);
  width: var(--icon-width);
}

.founder__content-blok__quotes-first {
  position: absolute;
  height: var(--icon-height);
  width: var(--icon-width);
  top: 0;
  left: 0;
}

.founder__content-blok__quotes-last {
  right: 0;
  bottom: -8px;
  transform: scaleX(-1);
}

@media (--tablet) {
  .founder__top__title {
    font-size: 42px;
  }
}
@media (--mobile) {
  .founder__top__title {
    font-size: 33px;
  }

  .founder__top__content,
  .founder__content-blok {
    font-size: 16px;
    text-align: justify;
  }

  .founder__content {
    position: absolute;
    bottom: calc(-1 * var(--mobile-bottom-offset));
    left: var(--mobile-bottom-padding);
    right: var(--mobile-bottom-padding);
    padding: 0 var(--mobile-bottom-padding);
  }

  * + .founder__image {
    margin-top: 42px;
  }

  .founder__image {
    position: relative;
  }

  .founder__image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      #000 6.35%,
      rgba(0, 0, 0, 0.26) 51.49%,
      rgba(0, 0, 0, 0) 70.64%
    );
  }
}
