.reasons {
  --vertical-padding: 72px;
  --top-padding: var(--vertical-padding);

  margin-top: var(--top-padding);
  padding-bottom: var(--vertical-padding);
}
@media (--tablet) {
  .reasons {
    --vertical-padding: 52px;
  }
}
@media (--mobile) {
  .reasons {
    --vertical-padding: 40px;
  }
}

* + .reasons {
  border-top: 5px solid var(--primary-500);
}

.reasons--without-border {
  border-top: none;
}

.companies + .reasons {
  margin-top: 0;
  padding-top: var(--top-padding);

  border-top: 5px solid var(--primary-500);
}
@media (--tablet) {
  .companies + .reasons {
    --top-padding: 16px;
  }
}
@media (--mobile) {
  .companies + .reasons {
    --top-padding: 12px;
  }
}

*:not(.companies) + .reasons {
  margin-top: 0;
  padding-top: var(--top-padding);

  border-top: 5px solid var(--primary-500);
}

.reasons__title {
  --font-size-factor: 1;

  display: flex;
  align-items: center;
  width: max-content;
  margin-left: auto;
  margin-right: auto;

  font-family: var(--font-family-title);
  line-height: 1.2;
  letter-spacing: 0.03em;
}
@media (--tablet) {
  .reasons__title {
    --font-size-factor: 0.85;
  }
}
@media (--mobile) {
  .reasons__title {
    --font-size-factor: 0.7;
  }
}

.reasons__title span:first-of-type {
  color: var(--primary-500);
  font-size: calc(104px * var(--font-size-factor));
  font-weight: 700;
}

.reasons__title span:last-of-type {
  max-width: 16ch;
  margin-left: 8px;

  font-size: calc(40px * var(--font-size-factor));
  font-weight: 700;
}

.reasons__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
}
* + .reasons__content {
  margin-top: 64px;
}
@media (--tablet) {
  .reasons__content {
    gap: 24px;
  }
}
@media (--mobile) {
  .reasons__content {
    grid-template-columns: 1fr;
  }
  * + .reasons__content {
    margin-top: 32px;
  }
}

.reasons__list {
  list-style: none;
}

.reasons__list__item {
  --number-size: 32px;
  --min-height: 52px;
  --vertical-padding: 6px;
  --horizontal-padding: calc((var(--min-height) - var(--number-size)) / 2);

  display: flex;
  align-items: center;
  gap: 8px;
  min-height: var(--min-height);
  padding-top: var(--vertical-padding);
  padding-right: 16px;
  padding-bottom: var(--vertical-padding);
  padding-left: var(--horizontal-padding);

  border-radius: calc(var(--min-height) / 2);

  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(
      129deg,
      rgba(255, 255, 255, 0.38) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );
  backdrop-filter: blur(12px);
}

.reasons__list__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  pointer-events: none;
  border-radius: calc(var(--min-height) / 2);
  border: 1px solid transparent;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    )
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

* + .reasons__list__item {
  margin-top: 16px;
}

.reasons__list__item__number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--number-size);
  height: var(--number-size);

  color: var(--black);
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.03em;

  user-select: none;
  border-radius: 999px;
  background-color: var(--primary-500);
}

.reasons__list__item__content {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.03em;
  color: var(--white);
}

.reasons__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 16px;
}
