@custom-media --page-max-width (max-width: 1140px);
@custom-media --tablet (max-width: 1024px);
@custom-media --mobile (max-width: 768px);
@custom-media --header-breakpoint (max-width: 768px);

@custom-media --wider-than-page-max-width (min-width: 1141px);
@custom-media --wider-than-tablet (min-width: 1025px);
@custom-media --wider-than-mobile (min-width: 769px);

:root {
  /* Sizes */
  --page-max-width: 1120px;

  --page-horizontal-padding: 24px;
  --page-left-padding: var(--page-horizontal-padding);
  --page-left-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-left)
  );
  --page-right-padding: var(--page-horizontal-padding);
  --page-right-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-right)
  );

  --header-height: 108px;

  /* Font families */
  --font-family-title: 'EB Garamond', sans-serif;
  --font-family-text: 'Helvetica Now Display', sans-serif;

  /* Z-indexes */
  --z-index-header: 10;
  --z-index-mobile-menu: 20;
  --z-index-mobile-menu-button: 30;

  /* Spacings */
  --spacing-56: 56px;

  /* Colors */
  --white: #ffffff;
  --white-rgb: 255, 255, 255;

  --black: #000000;
  --black-rgb: 0, 0, 0;

  --grey-900: #020202;
  --grey-600: #5b616e;

  --primary-500: #f0fa1d;
  --primary-500-rgb: 239, 250, 30;
  --primary-400: #eff844;
  --primary-300: #f0f76e;
  --primary-200: #f3f7a3;
  --primary-100: #f0f2bf3;
}
@media (--mobile) {
  :root {
    /* Sizes */
    --header-height: 80px;

    /* Spacings */
    --spacing-56: 32px;
  }
}
