.header {
  --color: var(--white);
  --logo-height: 26px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-index-header);

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--header-height);
  pointer-events: none;
}
.header::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -100px;
  left: 0;

  content: '';
  pointer-events: none;

  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    ease-in-out,
    rgba(0, 0, 0, 0) 100%
  );
}
@media (--mobile) {
  .header::before {
    bottom: -32px;
  }
}

.header .max-width {
  position: relative;

  display: flex;
  justify-content: space-between;
  height: 100%;
}

.header__hr {
  position: absolute;
  right: var(--page-right-padding);
  left: var(--page-left-padding);
  bottom: 0;

  height: 2px;

  border: none;
  background-image: linear-gradient(
    to right,
    rgba(var(--white-rgb), 0) 0%,
    rgba(var(--white-rgb), 0.6) 50%,
    rgba(var(--white-rgb), 0) 100%
  );
}

.header__logo {
  display: flex;
  align-items: center;
  height: 100%;

  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
}

.header__logo img {
  display: block;
  width: auto;
  height: var(--logo-height);
  user-select: none;
}

.header__links {
  --gap: 40px;

  display: flex;
  align-items: center;
}
@media (--header-breakpoint) {
  .header__links {
    display: none;
  }
}

.header__link {
  --padding-left: calc(var(--gap) / 2);

  position: relative;

  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);

  color: var(--color);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  text-decoration: none;

  transition: text-decoration-color 200ms;
  pointer-events: auto;
}
.header__link:not(:last-child) {
  --padding-right: calc(var(--gap) / 2);
}

.header__link::after {
  --scale: 0.2;

  position: absolute;
  top: 50%;
  right: var(--padding-right);
  left: var(--padding-left);

  content: '';
  display: block;
  height: 2px;

  background-image: linear-gradient(
    to right,
    rgba(var(--primary-500-rgb), 0) 0%,
    var(--primary-500) 50%,
    rgba(var(--primary-500-rgb), 0) 100%
  );
  transform: translateY(16px) scaleX(var(--scale));
  opacity: 0;
  transition:
    200ms opacity,
    200ms transform;
}
.header__link--current::after,
.header__link:hover::after {
  --scale: 1;

  opacity: 1;
}

.header__cta {
  pointer-events: auto;
}
* + .header__cta {
  margin-left: 12px;
}
