.comparative-table {
  --first-cell-width: 200px;
  --row-height: 50px;
  --gap: 10px;
  --border-radius: 16px;
  --padding: 25px 12px;
  --grid-template-columns: repeat(calc(var(--columns) + 1), 1fr);
  --background: linear-gradient(
    130deg,
    rgba(255, 255, 255, 0.14) 0%,
    ease-in-out,
    rgba(255, 255, 255, 0.07) 100%
  );
  --vertical-padding: 104px;

  position: relative;

  overflow: hidden;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
@media (--tablet) {
  .comparative-table {
    --grid-template-columns: var(--first-cell-width) repeat(var(--columns), 1fr);
    --vertical-padding: 60px;
  }
}
@media (--mobile) {
  .comparative-table {
    --first-cell-width: 120px;
    --grid-template-columns: var(--first-cell-width) repeat(var(--columns), 1fr);
    --vertical-padding: 40px;
  }
}

* + .comparative-table {
  border-top: 5px solid var(--primary-500);
}

.comparative-table--without-border {
  border-top: none;
}

.comparative-table__desktop-gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      130deg,
      rgba(var(--black-rgb), 0.8) 16%,
      rgba(var(--black-rgb), 0) 60%
    ),
    linear-gradient(
      123deg,
      rgba(var(--black-rgb), 0.8) 14%,
      rgba(var(--black-rgb), 0) 58%
    );
}
.comparative-table--without-gradient > .comparative-table__desktop-gradient {
  display: none;
}

.comparative-table__title {
  max-width: 22ch;
  font-family: var(--font-family-title);
  font-size: 50px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.03em;
}
@media (--mobile) {
  .comparative-table__title {
    max-width: 100%;

    font-size: 33px;
  }
}
.comparative-table__title strong {
  color: var(--primary-500);
}

.comparative-table__description {
  max-width: 50ch;

  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.03em;
}
@media (--mobile) {
  .comparative-table__description {
    max-width: 100%;

    font-size: 16px;
    line-height: 150%;
  }
}
* + .comparative-table__description {
  margin-top: 32px;
}
@media (--mobile) {
  * + .comparative-table__description {
    margin-top: 30px;
  }
}

@media (--tablet) {
  .comparative-table__gradient {
    position: relative;
    width: 100vw;

    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  .comparative-table__gradient::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: var(--first-cell-width);
    height: 100%;

    content: '';

    background-color: var(--black);
  }
  .comparative-table__gradient::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    content: '';
    width: 40px;

    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 1) 0%,
      ease-in-out,
      rgba(0, 0, 0, 0) 100%
    );
    pointer-events: none;
  }
}

* + .comparative-table__gradient {
  margin-top: 76px;
}
@media (--mobile) {
  * + .comparative-table__gradient {
    margin-top: 49px;
  }
}

@media (--tablet) {
  .comparative-table__scroll {
    position: relative;

    overflow-x: scroll;
  }
  .comparative-table__scroll::-webkit-scrollbar {
    display: none;
  }
}

@media (--tablet) {
  .comparative-table__padding {
    display: flex;
  }
  .comparative-table__padding::after {
    flex-shrink: 0;
    display: block;
    width: 0;
    margin-left: 40px;
    content: '\A0';
  }
}

.comparative-table__table {
  display: grid;
  grid-template-columns: var(--grid-template-columns);

  grid-auto-rows: minmax(50px, auto);
}
@media (--tablet) {
  .comparative-table__table {
    width: calc(var(--first-cell-width) + var(--columns) * 220px);
    flex-basis: calc(var(--first-cell-width) + var(--columns) * 220px);
    flex-shrink: 0;
  }
}
@media (--mobile) {
  .comparative-table__table {
    width: calc(var(--first-cell-width) + var(--columns) * 110px);
    flex-basis: calc(var(--first-cell-width) + var(--columns) * 110px);
    flex-shrink: 0;
  }
}

* + .comparative-table__table {
  margin-top: 76px;
}

.comparative-table__header {
  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-column-start: 2;
  grid-column-end: calc(var(--columns) + 2);
  min-height: var(--row-height);

  background: var(--background);
  border-radius: var(--border-radius);
}
@media (--tablet) {
  .comparative-table__header {
    background: none;
  }
}

.comparative-table__header__cell {
  color: var(--white);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--padding);
}
@media (--tablet) {
  .comparative-table__header__cell {
    order: var(--index);
  }
}

.comparative-table__header__cell__title,
.comparative-table__row__title {
  font-family: var(--font-family-text);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.54px;
}
.comparative-table__row__title {
  text-align: left;
}
.comparative-table__header__cell__title {
  max-width: 14ch;
  padding-left: 8px;
  padding-right: 8px;

  text-align: center;
}
@media (--mobile) {
  .comparative-table__header__cell__title,
  .comparative-table__row__title {
    font-size: 14px;
    letter-spacing: 0.42px;
  }
}

.comparative-table__header__cell--highlighted
  .comparative-table__header__cell__title,
.comparative-table__header__cell__logo {
  display: none;
}

@media (--tablet) {
  .comparative-table__header__cell--highlighted {
    order: 1;

    display: flex;
    flex-direction: column;
  }

  .comparative-table__header__cell--highlighted
    .comparative-table__header__cell__title {
    display: block;
    color: var(--primary-500);
  }

  .comparative-table__header__cell--highlighted img {
    display: none;
  }

  .comparative-table__header__cell--highlighted
    .comparative-table__header__cell__logo {
    display: block;

    width: 20px;
    height: 20px;
  }
}

* + .comparative-table__rows {
  margin-top: var(--gap);
  grid-column-start: 1;
  grid-column-end: calc(var(--columns) + 2);
}

.comparative-table__row {
  display: grid;
  grid-template-columns: var(--grid-template-columns);
  min-height: var(--row-height);

  background: var(--background);
  border-radius: var(--border-radius);
}
@media (--tablet) {
  .comparative-table__row {
    position: relative;
  }
}
* + .comparative-table__row {
  margin-top: var(--gap);
}

.comparative-table__row__label {
  color: var(--white);

  display: flex;
  align-items: center;

  padding-left: 32px;
  padding-right: 12px;
}
@media (--tablet) {
  .comparative-table__row__label {
    position: sticky;
    left: 0;

    background-color: rgb(27, 27, 27);

    z-index: 1;
  }
  .comparative-table__row__label::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;

    content: '';
    width: 16px;

    pointer-events: none;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 0%,
      ease-in-out,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.comparative-table__row__cell {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding);

  text-align: center;
  color: var(--primary-500);
}
@media (--tablet) {
  .comparative-table__row__cell {
    order: var(--index);

    font-size: 14px;
  }
}
.comparative-table__row__cell:not(:first-child)::after {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 50%;
  left: 0;
  transform: translateY(-50%);

  content: '';
  width: 0;
  height: 35px;

  border-left: 1px solid rgba(217, 217, 217, 0.3);
}
@media (--mobile) {
  .comparative-table__row__label
    + .comparative-table__row__cell:not(:first-child)::after {
    display: none;
  }
}

@media (--tablet) {
  .comparative-table__row__cell--highlighted {
    order: 1;
  }
}
.comparative-table__row__cell--highlighted::before {
  --vertical-offset: 4px;
  --horizontal-offset: 12px;

  position: absolute;
  top: var(--vertical-offset);
  right: var(--horizontal-offset);
  bottom: var(--vertical-offset);
  left: var(--horizontal-offset);

  content: '';

  background: var(--primary-500);
  border-radius: 8px;
}
@media (--tablet) {
  .comparative-table__row__cell--highlighted::before {
    position: unset;
  }
}

.comparative-table__row__cell--highlighted img {
  filter: brightness(0);
}

@media (--tablet) {
  .comparative-table__row__cell img {
    filter: saturate(0);
  }
  .comparative-table__row__cell--highlighted img {
    filter: unset;
  }
}

.comparative-table__row__cell span {
  position: relative;
}
