.footer {
  position: relative;
}
@media (--header-breakpoint) {
  .footer {
    padding-bottom: 32px;
  }
}

.footer__content {
  --vertical-padding: var(--spacing-56);

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
@media (--mobile) {
  .footer__content {
    --vertical-padding: 40px;

    display: block;
    text-align: center;
  }
}

.footer__hr {
  width: 100%;

  border: none;
  border-top: 2px solid var(--grey-600);
}

@media (--mobile) {
  * + .footer__content__column {
    margin-top: 40px;
  }
}

.footer__content__column h2 {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--grey-600);
  cursor: default;
}

.footer__content__column__logo {
  display: block;
}

.footer__content__column__logo img {
  width: 124px;
  height: auto;
}

.footer__content__column__links {
  --gap: 24px;

  list-style: none;
}

.footer__content__column__links li {
  display: block;
}

.footer__content__column__links li button {
  text-align: left;
}

.footer__content__column__links li a,
.footer__content__column__links li button {
  display: block;
  padding-top: calc(var(--gap) / 2);

  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.01em;
  text-decoration: none;
}
.footer__content__column__links li a:hover,
.footer__content__column__links li button:hover {
  text-decoration: underline;
}

* + .footer__content__column__sub-text {
  margin-top: 16px;
}

.footer__content__column__sub-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;

  color: var(--grey-600);
}

.footer__legal {
  --vertical-padding: 40px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}

.footer__legal p {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
}
@media (--tablet) {
  .footer__legal p {
    text-align: center;
  }
}
@media (--mobile) {
  .footer__legal p {
    max-width: 40ch;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer__legal p a {
  color: var(--black);
}
