.hero-form {
  --vertical-padding: 100px;

  position: relative;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
.hero-form--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}
@media (--tablet) {
  .hero-form {
    --vertical-padding: 60px;
  }
}
@media (--mobile) {
  .hero-form {
    --vertical-padding: 40px;
  }
}

.hero-form__wrap {
  display: flex;
}
.hero-form__content {
  max-width: 611px;

  width: 100%;
}
.hero-form__sparkle {
  width: 40px;
  height: 40px;
}

.hero-form__sparkle + .section-title {
  margin-top: 46px;
}
@media (--mobile) {
  .hero-form__sparkle + .section-title {
    margin-top: 24px;
  }
}

.hero-form__text p {
  max-width: 50ch;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.03em;
}

* + .hero-form__text {
  margin-top: 32px;
}

@media (--mobile) {
  .hero-form__text p {
    max-width: 100%;

    font-size: 16px;
    line-height: 150%;
  }
  * + .hero-form__text {
    margin-top: 30px;
  }
}

.hero-form__subtext {
  max-width: 50ch;
  color: var(--primary-500);
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.03em;
}

* + .hero-form__subtext {
  margin-top: 32px;
}

@media (--mobile) {
  .hero-form__subtext {
    max-width: 100%;

    font-size: 16px;
    line-height: 150%;
  }
  * + .hero-form__subtext {
    margin-top: 30px;
  }
}

.hero-form__image {
  max-width: 220px;
  margin-left: auto;
  margin-right: 50px;
}

@media (--tablet) {
  .hero-form__image {
    display: none;
  }
}

.hero-form__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
