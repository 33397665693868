.group {
  position: relative;
}

.group + .group {
  border-top: 5px solid var(--primary-500);
}

.group__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      130deg,
      rgba(var(--black-rgb), 0.8) 16%,
      rgba(var(--black-rgb), 0) 60%
    ),
    linear-gradient(
      123deg,
      rgba(var(--black-rgb), 0.8) 14%,
      rgba(var(--black-rgb), 0) 58%
    );
}
.group--without-gradient > .group__gradient {
  display: none;
}

.group__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;
}
.group__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 30%;
}
@media (--tablet) {
  .group__background img {
    object-position: 60% 50%;
  }
}
@media (--mobile) {
  .group__background img {
    object-position: 65% 50%;
  }
}

.group__background::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -100px;
  left: 0;

  content: '';

  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    ease-in-out,
    rgba(0, 0, 0, 0) 100%
  );
}
@media (--mobile) {
  .group__background::after {
    bottom: -32px;
  }
}

.group__body {
  position: relative;
}
