.companies {
  --vertical-padding: 82px;

  padding-bottom: var(--vertical-padding);
}
@media (--tablet) {
  .companies {
    --vertical-padding: 64px;
  }
}
@media (--mobile) {
  .companies {
    --vertical-padding: 46px;
  }
}
.companies--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}

.companies--small {
  --vertical-padding: 26px;

  background-color: rgba(var(--black-rgb), 0.3);
}

* + .companies {
  margin-top: var(--vertical-padding);
}

.hero + .companies {
  margin-top: 0;
  padding-top: var(--vertical-padding);

  border-top: 5px solid var(--primary-500);
}
@media (--mobile) {
  * + .companies {
    margin-top: 52px;
  }
}

.companies__title {
  max-width: 35ch;
  margin-left: auto;
  margin-right: auto;

  color: var(--white);
  text-align: center;
  font-family: var(--font-family-title);
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.03em;
  text-align: center;
}
.companies--small .companies__title {
  margin-top: 8px;

  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  font-style: italic;
}
.companies__title strong {
  color: var(--primary-500);
  font-weight: 700;
}
@media (--tablet) {
  .companies__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .companies__title {
    font-size: 26px;
  }
  .companies--small .companies__title {
    margin-top: 0;
  }
}

.companies__logos {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 12px;
  justify-content: center;
}
@media (--page-max-width) {
  .companies__logos {
    column-gap: 30px;
  }
}
@media (--mobile) {
  .companies__logos {
    column-gap: 16px;
    row-gap: 0;
  }
  .companies--small .companies__logos {
    column-gap: 24px;
  }
}

* + .companies__logos {
  margin-top: 24px;
}
.companies--small * + .companies__logos {
  margin-top: 0px;
}
@media (--mobile) {
  * + .companies__logos {
    margin-top: 20px;
  }
}

.companies__logo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.companies__logo img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}
@media (--page-max-width) {
  .companies__logo img {
    height: 90px;
  }
}
@media (--mobile) {
  .companies__logo img {
    height: 72px;
  }
  .companies--small .companies__logo img {
    height: 64px;
  }
}
