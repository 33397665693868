.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
  padding-right: var(--page-right-padding);
  padding-bottom: 48px;
  padding-left: var(--page-left-padding);
}
.main .loading:first-child {
  margin-top: calc(var(--header-height) + 48px);
}
