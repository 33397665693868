.hero {
  --vertical-padding: 100px;
  --gap: 32px;

  position: relative;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
.hero--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}

@media (--tablet) {
  .hero {
    --vertical-padding: 60px;
  }
}
@media (--mobile) {
  .hero {
    --vertical-padding: 40px;
    --gap: 24px;
  }
}

.hero__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      358deg,
      rgba(var(--black-rgb), 0.8) 4%,
      rgba(var(--black-rgb), 0) 26%
    ),
    linear-gradient(
      161deg,
      rgba(var(--black-rgb), 0.6) 11%,
      rgba(var(--black-rgb), 0) 90%
    ),
    linear-gradient(
      98deg,
      rgba(var(--black-rgb), 0.8) 14%,
      rgba(var(--black-rgb), 0) 88%
    );
}

.hero--without-gradient > .hero__gradient {
  display: none;
}

.hero .max-width {
  position: relative;
}

.hero__title {
  max-width: 17ch;

  font-family: var(--font-family-title);
  font-size: 50px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 1.5px;
  color: var(--white);
}

.hero--large-content .hero__title {
  max-width: 26ch;
}
.hero__title strong {
  font-weight: 700;
  color: var(--primary-500);
}
@media (--tablet) {
  .hero__title {
    font-size: 40px;
  }
}
@media (--mobile) {
  .hero__title {
    font-size: 32px;
  }
}

.hero__description {
  max-width: 35ch;

  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.03em;
}
.hero--large-content .hero__description {
  max-width: 48ch;
}
* + .hero__description {
  margin-top: var(--gap);
}
@media (--mobile) {
  .hero__description {
    font-size: 17px;
  }
}

* + .hero__description__cta {
  margin-top: var(--gap);
}
