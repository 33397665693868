.hero-form__form {
  margin-top: 62px;
}

@media (--mobile) {
  .hero-form__form {
    margin-top: 49px;
  }
}

.section-title + .hero-form__form {
  margin-top: 46px;
}
@media (--mobile) {
  .section-title + .hero-form__form {
    margin-top: 30px;
  }
}

.hero-form__form__title {
  font-family: var(--font-family-title);
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.03em;
}

@media (--mobile) {
  .hero-form__form__title {
    font-size: 18px;
  }
}

* + form {
  margin-top: 46px;
}

.hero-form__form__title + form {
  margin-top: 16px;
}

.hero-form__form--state-success form,
.hero-form__form--state-success .hero-form__form__title {
  display: none;
}

.hero-form__form__split {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.hero-form__form__input-container,
.hero-form__form__input-container-textarea {
  --border-radius: 16px;

  position: relative;

  display: flex;
  width: 100%;
}

.hero-form__form__input-container::before,
.hero-form__form__input-container-textarea::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  pointer-events: none;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.35),
      rgba(255, 255, 255, 0.13)
    )
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@media (--tablet) {
  .hero-form__form__split {
    display: flex;
    flex-direction: column;
  }
}

@media (--mobile) {
  .hero-form__form__split {
    gap: 12px;
  }
}

.hero-form__form__split + .hero-form__form__split {
  margin-top: 16px;
}

@media (--mobile) {
  .hero-form__form__split + .hero-form__form__split {
    margin-top: 12px;
  }
}

.hero-form__form__split--flex {
  display: flex;
}

.hero-form__form__input,
.hero-form__form__textarea {
  width: 100%;
  padding: 17px 24px;
  border-radius: var(--border-radius);
  background: linear-gradient(
    101deg,
    rgba(var(--white-rgb), 0.17) 15%,
    rgba(var(--white-rgb), 0.07) 175%
  );
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.03em;
  color: var(--white);
  border: 1px solid rgba(var(--white-rgb), 0);

  transition: border-color 200ms;
}

.hero-form__form__input:focus,
.hero-form__form__textarea:focus {
  border-color: rgba(var(--white-rgb), 1);
  outline: none;
}

@media (--mobile) {
  .hero-form__form__input,
  .hero-form__form__textarea {
    font-size: 16px;
  }
}

* + .hero-form__form__input,
* + .hero-form__form__textarea {
  margin-top: 6px;

  display: flex;
  align-items: center;
}

form > * + .hero-form__form__input-container,
* + .hero-form__form__input-container-textarea {
  margin-top: 16px;
}

@media (--mobile) {
  form > * + .hero-form__form__input-container,
  * + .hero-form__form__input-container-textarea {
    margin-top: 12px;
  }
}

.hero-form__form__textarea {
  resize: vertical;
  min-height: 103px;
}

.hero-form__form__error {
  min-height: 30px;
}

.hero-form__form__error p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: 0.04em;
  color: rgba(255, 255, 255, 0.5);
}

* + .hero-form__form__error {
  margin-top: 11px;
}

.hero-form__form__success__button {
  user-select: none;
  margin-left: auto;
  gap: 16px;
  padding: 16px 20px;
  background-color: var(--primary-500);
  color: var(--black);
  width: fit-content;

  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.03em;
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  align-items: center;
}

@media (--mobile) {
  .hero-form__form__success__button {
    margin-left: 0;
  }
}

.hero-form__form__success__button img {
  width: 15px;
  height: 15px;
}

.hero-form__form__cta,
.hero-form__form__success__button {
  display: flex;
  justify-content: flex-end;
}

@media (--mobile) {
  .hero-form__form__cta,
  .hero-form__form__success__button {
    justify-content: flex-start;
  }
}

* + .hero-form__form--state-error .hero-form__form__cta {
  margin-top: 11px;
}

* + .hero-form__form__cta {
  margin-top: 16px;
}

@media (--mobile) {
  * + .hero-form__form__cta {
    margin-top: 21px;
  }
}

.hero-form__form__success__content {
  padding-top: 79px;
}

* + .hero-form__form__success {
  margin-top: 11px;
}

.hero-form__form__success__content .hero-form__form__success__content__text h3 {
  font-family: var(--font-family-title);
  font-size: 28px;
  font-weight: 800;
  line-height: 140%;
  letter-spacing: 0.03em;
}

@media (--mobile) {
  .hero-form__form__success__content
    .hero-form__form__success__content__text
    h3 {
    font-size: 24px;
  }
}

.hero-form__form__success__content
  .hero-form__form__success__content__text
  h3
  b {
  color: var(--primary-500);
}

.hero-form__form__success__content
  .hero-form__form__success__content__text
  h3
  + p {
  margin-top: 46px;
}

@media (--mobile) {
  .hero-form__form__success__content
    .hero-form__form__success__content__text
    h3
    + p {
    margin-top: 36px;
  }
}

.hero-form__form__success__content img {
  display: none;
}

@media (--mobile) {
  .hero-form__form__success__content img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-form__form__success__content
    img
    + .hero-form__form__success__content__text
    h3 {
    margin-top: 69px;
  }
}

.hero-form__form__success__content p {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.03em;

  text-align: left;

  color: var(--white);
}
.hero-form__form__success__button .button__icon {
  --icon-size: 16px;
}
@media (--mobile) {
  .hero-form__form__success__content p {
    font-size: 16px;
    max-width: 28ch;
  }
}

* + .hero-form__form__success__button {
  margin-top: 66px;
}

@media (--mobile) {
  * + .hero-form__form__success__button {
    margin-top: 80px;
  }
}
