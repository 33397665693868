.title-section {
  --vertical-padding: 100px;
}
.title-section--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}
@media (--tablet) {
  .title-section {
    --vertical-padding: 50px;
  }
}
@media (--mobile) {
  .title-section {
    --vertical-padding: 40px;
  }
}

.title-section__content {
  color: var(--white);
  font-family: var(--font-family-title);
  font-size: 40px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.03em;
}
@media (--mobile) {
  .title-section__content {
    max-width: 25ch;

    font-size: 24px;
  }
}

.title-section__content strong {
  font-weight: 500;
  color: var(--primary-500);
}
