.section-title {
  --color: var(--white);
  font-family: var(--font-family-title);

  position: relative;

  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 1.3;
}

.section-title--align-center {
  align-items: center;
  text-align: center;
}

.section-title--size-medium {
  max-width: 22ch;

  font-size: 50px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 0.03em;
}
@media (--tablet) {
  .section-title--size-medium {
    font-size: 40px;
  }
}
@media (--mobile) {
  .section-title--size-medium {
    font-size: 32px;
  }
}

.section-title--title-upper-case .section-title-title {
  text-transform: uppercase;
}

.section-title b {
  color: var(--primary-500);
}
