@font-face {
  font-family: 'HelveticaNeueCyr';
  src:
    local('HelveticaNeueCyr-UltraLight'),
    url('/font-faces/HelveticaNeueCyr-UltraLight.woff2') format('woff2'),
    url('/font-faces/HelveticaNeueCyr-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCyr';
  src:
    local('HelveticaNeueCyr-Thin'),
    url('/font-faces/HelveticaNeueCyr-Thin.woff2') format('woff2'),
    url('/font-faces/HelveticaNeueCyr-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond Medium Italic'),
    local('EBGaramond-MediumItalic'),
    url('/font-faces/EBGaramond-MediumItalic.woff2') format('woff2'),
    url('/font-faces/EBGaramond-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond SemiBold Italic'),
    local('EBGaramond-SemiBoldItalic'),
    url('/font-faces/EBGaramond-SemiBoldItalic.woff2') format('woff2'),
    url('/font-faces/EBGaramond-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond ExtraBold'),
    local('EBGaramond-ExtraBold'),
    url('/font-faces/EBGaramond-ExtraBold.woff2') format('woff2'),
    url('/font-faces/EBGaramond-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond Bold Italic'),
    local('EBGaramond-BoldItalic'),
    url('/font-faces/EBGaramond-BoldItalic.woff2') format('woff2'),
    url('/font-faces/EBGaramond-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond Regular'),
    local('EBGaramond-Regular'),
    url('/font-faces/EBGaramond-Regular.woff2') format('woff2'),
    url('/font-faces/EBGaramond-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond SemiBold'),
    local('EBGaramond-SemiBold'),
    url('/font-faces/EBGaramond-SemiBold.woff2') format('woff2'),
    url('/font-faces/EBGaramond-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond ExtraBold Italic'),
    local('EBGaramond-ExtraBoldItalic'),
    url('/font-faces/EBGaramond-ExtraBoldItalic.woff2') format('woff2'),
    url('/font-faces/EBGaramond-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond Italic'),
    local('EBGaramond-Italic'),
    url('/font-faces/EBGaramond-Italic.woff2') format('woff2'),
    url('/font-faces/EBGaramond-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond Medium'),
    local('EBGaramond-Medium'),
    url('/font-faces/EBGaramond-Medium.woff2') format('woff2'),
    url('/font-faces/EBGaramond-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB Garamond';
  src:
    local('EB Garamond Bold'),
    local('EBGaramond-Bold'),
    url('/font-faces/EBGaramond-Bold.woff2') format('woff2'),
    url('/font-faces/EBGaramond-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Thin Ita'),
    local('HelveticaNowDisplay-ThinIta'),
    url('/font-faces/HelveticaNowDisplay-ThinIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-ThinIta.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Light'),
    local('HelveticaNowDisplay-Light'),
    url('/font-faces/HelveticaNowDisplay-Light.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Bold Ita'),
    local('HelveticaNowDisplay-BoldIta'),
    url('/font-faces/HelveticaNowDisplay-BoldIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-BoldIta.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Reg Ita'),
    local('HelveticaNowDisplay-RegIta'),
    url('/font-faces/HelveticaNowDisplay-RegIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-RegIta.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display ExtLt Ita'),
    local('HelveticaNowDisplay-ExtLtIta'),
    url('/font-faces/HelveticaNowDisplay-ExtLtIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-ExtLtIta.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Med Ita'),
    local('HelveticaNowDisplay-MedIta'),
    url('/font-faces/HelveticaNowDisplay-MedIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-MedIta.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display ExtLt'),
    local('HelveticaNowDisplay-ExtLt'),
    url('/font-faces/HelveticaNowDisplay-ExtLt.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-ExtLt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display ExtBlk'),
    local('HelveticaNowDisplay-ExtBlk'),
    url('/font-faces/HelveticaNowDisplay-ExtBlk.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-ExtBlk.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Bold'),
    local('HelveticaNowDisplay-Bold'),
    url('/font-faces/HelveticaNowDisplay-Bold.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display ExtBd Ita'),
    local('HelveticaNowDisplay-ExtBdIta'),
    url('/font-faces/HelveticaNowDisplay-ExtBdIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-ExtBdIta.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Regular'),
    local('HelveticaNowDisplay-Regular'),
    url('/font-faces/HelveticaNowDisplay-Regular.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display ExBlk It'),
    local('HelveticaNowDisplay-ExtBlkIta'),
    url('/font-faces/HelveticaNowDisplay-ExtBlkIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-ExtBlkIta.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Thin'),
    local('HelveticaNowDisplay-Thin'),
    url('/font-faces/HelveticaNowDisplay-Thin.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Light Ita'),
    local('HelveticaNowDisplay-LightIta'),
    url('/font-faces/HelveticaNowDisplay-LightIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-LightIta.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display ExtBd'),
    local('HelveticaNowDisplay-ExtraBold'),
    url('/font-faces/HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Medium'),
    local('HelveticaNowDisplay-Medium'),
    url('/font-faces/HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Hairline'),
    local('HelveticaNowDisplay-HairlineI'),
    url('/font-faces/HelveticaNowDisplay-HairlineI.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-HairlineI.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Black Ita'),
    local('HelveticaNowDisplay-BlackIta'),
    url('/font-faces/HelveticaNowDisplay-BlackIta.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-BlackIta.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Black'),
    local('HelveticaNowDisplay-Black'),
    url('/font-faces/HelveticaNowDisplay-Black.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src:
    local('Helvetica Now Display Hairline'),
    local('HelveticaNowDisplay-Hairline'),
    url('/font-faces/HelveticaNowDisplay-Hairline.woff2') format('woff2'),
    url('/font-faces/HelveticaNowDisplay-Hairline.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
